import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h2>Contact Us</h2>
      <div className="contact-container">
        {/* Left Column - Contact Form (unchanged) */}
        <div className="contact-form-wrapper">
          <form 
            id="contact-form"
            action="https://formsubmit.co/admin@velmark.co.uk" 
            method="POST"
          >
            <input type="hidden" name="_subject" value="New Contact Form Submission" />
            <input type="hidden" name="_template" value="table" />
            <input type="hidden" name="_captcha" value="false" />

            <div className="form-group">
              <input 
                type="text" 
                name="name" 
                placeholder="Your Name" 
                required 
              />
            </div>
            <div className="form-group">
              <input 
                type="email" 
                name="email" 
                placeholder="Your Email" 
                required 
              />
            </div>
            <div className="form-group">
              <textarea 
                name="message" 
                placeholder="Your Message" 
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-btn">
              Send Message
            </button>
          </form>
        </div>

        {/* Right Column - Enhanced Contact Info */}
        <div className="contact-info">
          <div className="address-card">
            <h3>Our Location & Contact</h3>
            <div className="contact-details">
              <div className="contact-item">
                <span className="contact-icon">📍</span>
                <p>92 Goodmayes Rd, Ilford IG3 9UU</p>
              </div>
              <div className="contact-item">
                <span className="contact-icon">📞</span>
                <p><a href="tel:+442081234567">0844 351 0691</a></p>
              </div>
              <div className="contact-item">
                <span className="contact-icon">✉️</span>
                <p><a href="mailto:admin@velmark.co.uk">admin@velmark.co.uk</a></p>
              </div>
              <div className="contact-item">
                <span className="contact-icon">🕒</span>
                <p>Mon-Fri: 9am-5pm</p>
              </div>
            </div>
          </div>
          <div className="map-container">
            <iframe
              title="Velmark Financial Services Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.874366105687!2d0.0994113157699236!3d51.56207917964272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a7e8b5b5b5b5%3A0x5b5b5b5b5b5b5b5b!2s92%20Goodmayes%20Rd%2C%20Ilford%20IG3%209UU!5e0!3m2!1sen!2suk!4v1620000000000!5m2!1sen!2suk"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;