import React from 'react';
import './About.css';
import HandshakeImage from '../assets/HandshakeImage.jpg';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about-container">
        <div className="content-wrapper">
          {/* Left Column - Image */}
          <div className="about-image">
            <img 
              src={HandshakeImage} 
              alt="Mortgage advisor discussing property financing" 
            />
          </div>

          {/* Right Column - Content */}
          <div className="about-content">
            <h2>About Velmark Financial Services</h2>
            
            <div className="content-block">
              <p className="lead-text">
                Leading mortgage brokerage offering whole-of-market expertise. 
                Tailored solutions, informed guidance, and transparent service 
                for all your mortgage needs.
              </p>
              
              <p>
                At Velmark Financial Services, we pride ourselves on the quality 
                of service we provide. With years of experience in the mortgage 
                and protection industry, we ensure you fully understand every 
                step of the process while delivering fast, efficient, and 
                professional service.
              </p>
              
              <blockquote className="mission-quote">
                "We will hold your hand every step of the way!"
              </blockquote>
            </div>

            <div className="content-block">
              <h3>Why Choose Velmark?</h3>
              <ul className="expertise-list">
                <li>
                  <span className="icon">🔍</span>
                  <span>Comprehensive analysis of 200+ BTL mortgage products</span>
                </li>
                <li>
                  <span className="icon">🏆</span>
                  <span>Whole-of-market impartial advice</span>
                </li>
                <li>
                  <span className="icon">📊</span>
                  <span>Access to exclusive mortgage rates</span>
                </li>
                <li>
                  <span className="icon">🛡️</span>
                  <span>FCA regulated through Connect IFA Ltd</span>
                </li>
              </ul>
            </div>

            <div className="disclaimer">
              <p>
                <small>
                  
                  The Financial Conduct Authority does not regulate most buy to let mortgages.<br />
                  Velmark Financial Services is an appointed representative of Connect IFA Ltd which 
                  is authorised and regulated by the Financial Conduct Authority.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;