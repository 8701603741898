import React from 'react';

import './Hero.css';

const Hero = () => {
    return (
        <section className="hero">
            {/* Video Background */}
            <video autoPlay loop muted playsInline className="hero-video">
                <source src="/hero-video (4).mp4" type="video/mp4" /> {/* If video is in public folder */}
                {/* OR */}
                <source src={require('../assets/hero-video (4).mp4')} type="video/mp4" /> {/* If video is in src/assets */}
                Your browser does not support the video tag.
            </video>
            {/* Text Content */}
            <div className="hero-content">
                <h1>Welcome to Velmark Financial Services</h1>
                <p>Your trusted partner in mortgage advice.</p>
                <a href="#services" className="cta">Explore Our Services</a>
            </div>
        </section>
    );
};

export default Hero;