import React from 'react';
import './Services.css';

const Services = () => {
  const services = [
    {
      title: "Buy-to-Let Mortgage",
      description: "Expert advice for property investors to maximize rental income and portfolio growth."
    },
    {
      title: "Residential Mortgage",
      description: "Tailored solutions for homeowners looking to move or refinance their property."
    },
    {
      title: "Bridging Loans",
      description: "Short-term financing solutions for property purchases before securing long-term funding."
    },
    {
      title: "Protection",
      description: "Comprehensive insurance products to safeguard your property investments and income."
    }
  ];

  return (
    <section id="services" className="services">
      <div className="services-header">
        <h2>Our Mortgage Services</h2>
        <p className="subtitle">Tailored financial solutions for your property needs</p>
      </div>
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;